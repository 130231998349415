<template>
  <el-button
    v-if="label"
    text
    plain
    class="px-3"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <el-icon><Plus /></el-icon>
    <span>{{ label }}</span>
  </el-button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>
