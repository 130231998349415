<template>
  <div class="flex">
    <el-select
      v-model="localValue"
      v-cancel-read-only
      class="w-full"
      filterable
      clearable
      multiple
      default-first-option
      @change="updateDays"
    >
      <el-option
        v-for="day in days"
        :key="day.value"
        :value="day.value"
        :label="day.label"
      />
    </el-select>

    <!-- Delete -->
    <el-button
      icon="Delete"
      class="ml-2 el-button--text-icon"
      :title="$t('common.interactions.buttons.remove')"
      @click="$emit('delete')"
    />
  </div>
</template>

<script>
import get from 'just-safe-get'
import { DAYS_OF_WEEK } from '@/utils/date'

function genInitialDayConstrain(enabled = false) {
  return {
    slots: [
      {
        end: null,
        start: null,
        enabled
      }
    ]
  }
}

export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    localValue: {
      get() {
        //show days in order
        return DAYS_OF_WEEK.reduce((selectedDays, day) => {
          const isEnabled = get(
            this.modelValue,
            `${day}.slots.0.enabled`,
            false
          )
          if (isEnabled) {
            selectedDays.push(day)
          }
          return selectedDays
        }, [])
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    translations() {
      return {
        monday: this.$t('common.days.monday'),
        tuesday: this.$t('common.days.tuesday'),
        wednesday: this.$t('common.days.wednesday'),
        thursday: this.$t('common.days.thursday'),
        friday: this.$t('common.days.friday'),
        saturday: this.$t('common.days.saturday'),
        sunday: this.$t('common.days.sunday')
      }
    },
    days() {
      return DAYS_OF_WEEK.map((day) => {
        return {
          value: day,
          label: this.translations[day]
        }
      })
    }
  },
  methods: {
    updateDays(selectedDays) {
      this.localValue = DAYS_OF_WEEK.reduce(
        (daysConstraints, day) => {
          daysConstraints[day] = genInitialDayConstrain(
            selectedDays.includes(day)
          )
          return daysConstraints
        },
        { enabled: true }
      )
    }
  }
}
</script>
